<template>

    
    <div>
        <b-modal :ref="'add-modal-' + targetId" :key="'time-modal-' + targetId" hide-footer centered title="Assign a task" size="sm">
      <div>
        <div style="width: 100%;">
          <p>Task</p>
          <treeselect v-model="newTaskId" :multiple="false" :options="mappedTasks" :clearable="false" style="width: 100%;">
            <template #option="{ node }">{{ node.title }}</template>
          </treeselect>
        </div>

        <div class="mb-1 mt-1">
          <b-form-radio-group v-model="selectedPeriod">
            <b-form-radio value="AM">AM</b-form-radio>
            <b-form-radio value="PM">PM</b-form-radio>
          </b-form-radio-group>
        </div>

        <div class="d-flex justify-content-between mt-1" style="width: 100%;">
          <div style="width: 48%;">
            <p>Start Time</p>
            <flat-pickr v-model="start_time" class="form-control" :config="startPickerConfig" />
          </div>

          <div style="width: 48%;">
            <p>End Time</p>
            <flat-pickr v-model="end_time" class="form-control" :config="endPickerConfig" />
          </div>
        </div>
      </div>

      <div class="d-flex mt-3 justify-content-end">
        <b-button variant="primary" @click="saveAddChanges(targetId)">Save</b-button>
      </div>
    </b-modal>
        
        <b-button class="popoverBtn" variant="outline-secondary" @contextmenu.prevent="$refs[targetId].open" @click="optionClicked(targetId)" v-if=" userData.status=='active'&& ($Can('add_task_to_all') || ($Can('add_task_to_department')&&userData.departmentIds.includes(departmentId)))">
          <span v-if="userDataSchedule.multiple_sites">

<feather-icon
icon="AlertCircleIcon"
size="12"
v-b-tooltip.hover title="User not assigned to center."
class="warning-button"

/>
</span> 
          Click to assign   
       
  </b-button>

      <vue-context :ref="targetId" :key="'right-click-' + targetId">
        <li>
          <b-link v-for="data in menuData" :key="data.text" class="d-flex align-items-center" @click="optionClicked(targetId)">
            <feather-icon :icon="data.icon" size="16" />
            <span class="ml-75">{{ data.text }}</span>
          </b-link>
        </li>
      </vue-context>
    </div>
  </template>
  
  <script>
  import store from '@/store';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  import VueContext from 'vue-context';
  import flatPickr from 'vue-flatpickr-component';
  import Treeselect from '@riophae/vue-treeselect';
  
  export default {
    components: {
      ToastificationContent,
      VueContext,
      flatPickr,
      Treeselect,
    },
  
    props: {
      day: Object,
      oldDay: Object,
      tasks: Array,
      targetId: String,
      value: Object,
      dayIndex: Number,
      formShow: Boolean,
      userRole: String,
      departmentId: Number,
      userId:Number,
      userDataSchedule:Object,
      dayName:String,
      period:String,
    },
  
    data() {
      return {
        userData: JSON.parse(localStorage.getItem('userData')),
        userPermms: JSON.parse(localStorage.getItem('userPerms')),
        todayDate: new Date().toISOString().slice(0, 10),
        showPopover: false,
        showHover: false,
        showTimePicker: false,
        selectedPeriod: this.period ?? 'AM',
        start_time: (this.selectedPeriod == 'AM' || this.period == 'AM') ? '09:00' : '14:00',
        end_time:(this.selectedPeriod == 'AM' || this.period == 'AM') ? '13:00' : '16:00',
        startPickerConfig: this.getPickerConfig('09:00', '14:00'),
        endPickerConfig: this.getPickerConfig('13:00', '16:00'),
        newTaskId: null,
        mappedTasks: [],
        menuData: [
          { icon: 'PlusIcon', text: 'Add New Task', where: 'add' },
     
        ],
      };
    },

  
    computed: {

  
      localShowPopover: {
        get() {
          return this.value;
        },
        set(newValue) {
          this.$emit('input', newValue);
        },
      },
    },
  
    watch: {
      selectedPeriod(newPeriod) {
        this.updatePickerConfig(newPeriod);
      },
  
      start_time() {
        this.endPickerConfig.minTime = this.start_time;
        if (this.start_time > this.end_time) {
          this.end_time = this.start_time;
        }
      },
    },
  

    methods: {
      showOldTask(day) {
        if (day.change_awaits) {
          this.$set(day, 'isHovered', true);
        }
      },
      hideOldTask(day) {
        this.$set(day, 'isHovered', false);
      },
  
      buttonStyle(day) {
        if (!day.task || !day.task.color) {
          return {};
        }
  
        const color = this.displayedColor || day.task.color;
  
        return {
          backgroundColor: color.bgColor,
          color: color.textColor,
          position: 'relative',
        };
      },
  
      getPickerConfig(minTime, maxTime) {
        return {
          static: true,
          enableTime: true,
          noCalendar: true,
          dateFormat: 'H:i',
          time_24hr: true,

        };
      },
  
      updatePickerConfig(period) {
        // const minTime = period === 'AM' ? '00:00' : '12:00';
        // const maxTime = period === 'AM' ? '12:00' : '23:59';
        // this.startPickerConfig = this.getPickerConfig(minTime, maxTime);
        // this.endPickerConfig = this.getPickerConfig(minTime, maxTime);

        this.start_time = period === 'AM' ? '09:00' : '14:00';
        this.end_time = period === 'AM' ? '13:00' : '16:00';
      },
  
      optionClicked(targetId) {
        
         this.selectedPeriod = this.period
          this.mappedTasks = this.tasks.map((task) => ({ label: task.title, id: task.id }));
          // Tüm kullanıcılara görev ekleme yetkisini kontrol et
          if (this.$Can('add_task_to_all') || this.userData.departmentIds.includes(this.departmentId) ) {
            // Departman kullanıcılarına görev ekleme yetkisini ve departman eşleşmesini kontrol et
            if (this.$Can('add_task_to_department')) {
              this.$nextTick(() => {
                const modalRef = this.$refs['add-modal-' + targetId];
  
                if (modalRef) {
                  modalRef.show();
                } else {
                  console.error(`Add modal reference not found for targetId: ${targetId}`);
                }
              });
            } else {
              // Departman kullanıcılarına görev ekleme yetkisi yok
              this.showWarningToast('You do not have permission for this action.');
            }
          } else {
            // Tüm kullanıcılara görev ekleme yetkisi yok
            this.showWarningToast('You do not have permission for this action.');
          }
   
      },
  
      deleteTask(targetId) {
        // Confirm deletion
        this.$bvModal
          .msgBoxConfirm('Are you sure you want to delete this task?', {
            title: 'Confirm Deletion',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              store
                .dispatch('dashboard/deleteTask', { taskId: targetId })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Successful',
                      text: '✔️ Task deleted successfully',
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  });
                  this.$emit('changeTaskCompleted');
                })
                .catch((error) => {
                  console.error(error);
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'An error occurred',
                      text: 'Please try again later or contact support.',
                      icon: 'AlertCircleIcon',
                      variant: 'danger',
                    },
                  });
                });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      },
  
      saveTimeChanges(targetId) {
        this.updateScheduleTime(targetId);
      },
  
      saveAddChanges(targetId) {
        if (!this.newTaskId) {
          this.showWarningToast('Please select a task before saving.');
          return;
        }
        this.addNewSchedule(targetId);
      },
  
      updateScheduleTime(targetId) {
        store
          .dispatch('dashboard/updateScheduleTime', { task: this.value, start_time: this.start_time, end_time: this.end_time, period: this.selectedPeriod })
          .then(() => {
            this.showSuccessToast('✔️ Task Update Successful');
            this.hideModal(targetId);
            this.$emit('changeTaskCompleted');
          })
          .catch(() => {
            this.showErrorToast();
            this.$emit('formShow', false);
          });
      },
  

      addNewSchedule(targetId) {
      store
        .dispatch('dashboard/addNewScheduleDashboard', {
          userData: this.userDataSchedule,
          new_task_id: this.newTaskId,
          start_time: this.start_time,
          end_time: this.end_time,
          period: this.selectedPeriod,
          week:this.userDataSchedule.week,
          year:this.userDataSchedule.year,
          day:this.dayName,
          removeConflict:false,
        })
        .then((response) => {
          
       
          if(response.data.message=='Another Site'){

      this.$swal({
        title: 'The user is assigned to another site',
        text: 'User tasks in ' + response.data.site + ' will be removed. Do you want to continue?',
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: 'Continue',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.formShow = true;

          store
          .dispatch('dashboard/addNewScheduleDashboard', 

            {
          userData: this.userDataSchedule,
          new_task_id: this.newTaskId,
          start_time: this.start_time,
          end_time: this.end_time,
          period: this.selectedPeriod,
          week:this.userDataSchedule.week,
          year:this.userDataSchedule.year,
          day:this.dayName,
          removeConflict:true,
        }


            )
            .then((res) => {
              this.showSuccessToast('✔️ Task Update Successful');
              this.hideModal(targetId);
              this.$emit('changeTaskCompleted');
            })
            .catch((error) => {
              console.log('error ', error);
            });
        }
      });

        }
        else{
          this.showSuccessToast('✔️ Task Update Successful');
          this.hideModal(targetId);
          this.$emit('changeTaskCompleted');
        }

 
        })
        .catch(() => {
          this.showErrorToast();
          this.formShow=false;
        });
    },
  
      showSuccessToast(message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Successful',
            text: message,
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        });
      },
  
      showWarningToast(message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            text: message,
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        });
      },
  
      showErrorToast() {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            text: 'Please try again later or contact support.',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        });
      },

      showErrorMessage(message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            text: message,
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        });
      },
  
      hideModal(targetId) {
        this.$refs['time-modal-' + targetId]?.hide();
        this.$refs['add-modal-' + targetId]?.hide();
      },
  
      changeTask(newTask) {
        if (this.$Can('change_task_for_all') || this.userData.departmentIds.includes(this.departmentId)) {
          if (this.$Can('change_task_for_department')  || this.userId == this.userData.id) {
            this.$emit('formShow', true);
            store
              .dispatch('dashboard/updateSchedule', { oldTask: this.value, newTask })
              .then(() => {
                this.showSuccessToast('✔️ Task Update Successful');
                this.$root.$emit('bv::hide::popover');
                this.$emit('changeTaskCompleted');
              })
              .catch(() => {
                this.showErrorToast();
                this.$emit('formShow', false);
              });
          
          }
          
          else {
            this.showWarningToast('You do not have permission for this action.');
          }
        } else {
          this.showWarningToast('You do not have permission for this action.');
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .time-picker-container {
    display: flex;
    align-items: center;
  }
  .time-picker-container > * {
    margin-right: 10px;
  }
  .popoverBtn {
    height: 36px;
    font-size: 0.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
    width: 100%;
    border: none;
    cursor: pointer;
    width: calc(100% - 40px);
  }
  .popoverBtn:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3) !important;
    transition: box-shadow 0.3s ease-in-out !important;
  }
  .time-info-container {
    display: flex;
    justify-content: space-between;
    border-top: solid 1px #666;
  }
  .time-info {
    display: inline-block;
  }
  
  .strikethrough {
    text-decoration: line-through;
  }
  
  @media screen and (min-width: 768px) {
    .popoverBtn {
      min-width: 150px;
      max-width: 150px;
    }
  }
  @media screen and (min-width: 992px) {
    .popoverBtn {
      min-width: 125px;
      max-width: 125px;
    }
  }
  @media screen and (min-width: 1900px) {
    .popoverBtn {
      min-width: 220px;
      max-width: 220px;
    }
  }
  </style>
  <style lang="scss">
  @import '~vue-context/dist/css/vue-context.css';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
  